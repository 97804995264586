import endpoints from 'utilities/endpoints-helper/endpoints-helper';

const ACTION_TO_ENDPOINT = {
	doNotSell: endpoints('doNotSellData'),
	seeMyData: endpoints('seeData'),
	deleteMyData: endpoints('deleteData'),
};

export const dataManagementRequest = (action, { fname, lname, email, phone }) => {
	const endpoint = ACTION_TO_ENDPOINT[action];

	if (!endpoint) {
		throw new Error(`Unknown action: ${action}`);
	}

	return fetch(ACTION_TO_ENDPOINT[action], {
		method: 'POST',
		headers: {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			fname,
			lname,
			email,
			phone,

			origin: window.location.origin,
		}),
	}).then((response) => {
		return response;
	});
};
